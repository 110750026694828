import "./app.scss";

import "./vendor/arrayFrom";
import "./vendor/intersection-observer";
import objectFitImages from "./vendor/objectFitImage";
import smoothScrollPolyfill from "./vendor/smoothscroll";
import Swiper from "swiper";

const slider = document.querySelector("[data-slider]");
const dataObserve = Array.from(document.querySelectorAll("[data-observe]"));
const smoothScroll = Array.from(
    document.querySelectorAll("[data-smooth-scroll]")
);

const lazyLoad = (target, cb, config = {}) => {
    const io = new IntersectionObserver(
        (entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    cb(entry);
                    observer.disconnect();
                }
            });
        },
        {
            rootMargin: "-100px",
            threshold: 0,
            ...config
        }
    );

    io.observe(target);
};

const bulletColors = {
    1: 'hover:bg-green-500 active-bg-green-500',
    2: 'hover:bg-lightblue-500 active-bg-lightblue-500',
    3: 'hover:bg-blue-500 active-bg-blue-500'
}

function initCarousel() {
    const sliderId = slider.getAttribute("data-slider");
    const prev = document.querySelector(`[data-slide-prev="${sliderId}"]`);
    const next = document.querySelector(`[data-slide-next="${sliderId}"]`);
    new Swiper(slider, {
        loop: true,
        slidesPerView: 1,
        navigation: {
            nextEl: next,
            prevEl: prev,
            disabledClass: "slide-disabled"
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
            renderBullet: function(index, className) {
                return '<span class="' + className + ' mx-2 w-10 h-3 rounded-full inline-block bg-blue-100 transition-bg ' + bulletColors[index + 1] +'"></span>';
            },
            bulletActiveClass: 'active'
        },
    });
}

function appendClasses(entry): void {
    const target = entry.target;
    const classes = target.getAttribute("data-classes").split(" ");
    target.classList.remove(...classes);
}

function scrollIntoViewElement(element: Element): void {
    element.scrollIntoView({
        behavior: "smooth"
    });
}
document.addEventListener("DOMContentLoaded", () => {
    objectFitImages();
    smoothScrollPolyfill.polyfill();
    dataObserve.forEach(e => lazyLoad(e, appendClasses, {rootMargin: "-200px"}));
    smoothScroll.forEach(e => {
        e.addEventListener("click", event => {
            const element = document.getElementById(e.getAttribute("href").substr(2));
            if (element) {
                event.preventDefault();
                scrollIntoViewElement(document.getElementById(e.getAttribute("href").substr(2)));
            }
        });
    });

    if(slider) {
        lazyLoad(slider, initCarousel, { rootMargin: "100px" });
    }

    const button = document.querySelector("[data-toggle-menu]");
    const menu = document.querySelector("[data-menu]");
    button.addEventListener('click', () => {
        menu.classList.toggle('open-menu-wrapper');
        button.classList.toggle('open-toggle-menu')
    })
})


